import * as React from 'react'
import { graphql } from 'gatsby'

import { withLayout } from 'components/Layout'
import StaticPage, { StaticPageProps } from 'components/pages/page/StaticPage'

import 'stylesheets/all.scss'

/**
 * Mediator between a real pages component and Gatsby conventions:
 * decoupled for the tests sake
 * (currently graphql is not available in Storybook)
 */
const staticPage = (props: StaticPageProps) => <StaticPage {...props} />

export const query = graphql`
  query staticPageBySlug($filePath: String) {
    # Site metadata
    site {
      siteMetadata {
        title
        description
        logoTitle
        logoSecondaryTitle
        logoImageAlt
      }
    }
    # Page content
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        title
        dateRaw: date
        date(formatString: "MMMM DD, YYYY")
        description
        keywords
        author
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  }
`

export default withLayout(staticPage)
