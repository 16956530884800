import * as React from 'react'
import { Container } from 'react-bootstrap'

// tslint:disable-next-line:import-name
import Img from 'gatsby-image'

import WebsiteConfig from 'WebsiteConfig'

import getWebPageCombined from 'shared/util/schema/getWebPageCombined'

import Seo from 'components/shared/Seo/Seo'
import PageSection from 'components/shared/PageSection/PageSection'
import JsonLd from 'components/scripts/JsonLd/JsonLd'
import PageHeader from 'components/shared/PageHeader/PageHeader'
import PageFooter from 'components/shared/PageFooter/PageFooter'

import './StaticPage.scss'

export type StaticPageProps = {
  websiteConfig: WebsiteConfig
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: any
    }
    markdownRemark: StaticPageContent
  }
}

export type StaticPageContent = {
  html: string
  frontmatter: {
    title: string
    name: string
    description: string
    date: string
    dateRaw: string
    image?: {
      childImageSharp: ChildImageSharp
    }
    author: string
    keywords: string
  }
}

export class StaticPage extends React.Component<StaticPageProps> {
  render(): React.ReactNode {
    const { markdownRemark: page } = this.props.data
    const imageDef = page.frontmatter.image
    const image = imageDef && imageDef.childImageSharp.fluid
    const imageSrc = image && image.src

    const {
      menuItems,
      footer,
      schema,
    } = this.props.websiteConfig

    return (
      <>
        <Seo
          title={page.frontmatter.title}
          description={page.frontmatter.description}
          article={true}
          image={imageSrc}
          websiteConfig={this.props.websiteConfig}
        />
        <JsonLd>{getWebPageCombined(schema)}</JsonLd>

        <PageHeader
          pathname={this.props.location.pathname}
          items={menuItems}
          texts={{
            ...this.props.data.site.siteMetadata,
          }}
        />
        <PageSection id={page.frontmatter.name} className="Page d-md-flex">
          <Container>
            <article
              className="
                Page__container
                d-flex flex-column
                align-items-center
                justify-content-center
                u-full-width
                text-left
              "
            >
              <header className="mb-4">
                <h1 className="Page__title page-title mb-3 mt-lg-4">{page.frontmatter.title}</h1>
              </header>
              {image && (
                <figure className="Page__imageContainer">
                  <Img
                    fluid={image}
                    className="Page__image"
                    alt={page.frontmatter.title}
                  />
                </figure>
              )}
              <div
                className="Page__body"
                dangerouslySetInnerHTML={{ __html: page.html }}
              />
            </article>
          </Container>
        </PageSection>
        <PageFooter {...footer}/>
      </>
    )
  }
}

export default StaticPage
